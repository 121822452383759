import React from 'react';
import { useQuery } from '@apollo/client';
import { locationShape } from 'router/prop-types';
import { flattenEdges } from 'utils/helpers';
import { LINK_INTERFACE_SUPPORTED_TYPES } from 'utils/constants';
import NotFoundView from 'views/NotFoundView/NotFoundView';
import {
  GenreCard,
  imageTypes,
  Text,
  Grid,
  Content,
  PageWrapper,
  Metadata,
} from 'components';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import { ClickTracker } from 'components/Tracking';
import getGenreCollection from './getGenreCollection.gql';

function GenresView({ location }) {
  const {
    loading,
    error,
    data,
  } = useQuery(
    getGenreCollection,
    {
      variables: {
        collectionId: location.params.id,
      },
      skip: !location.params.id,
    },
  );

  if ((loading && !data) || error) {
    return null;
  }

  if (!data.viewer.block?.links) {
    return (
      <NotFoundView />
    );
  }

  const view = data.viewer.block;

  const items = flattenEdges(data.viewer.block.links)
    .filter(Boolean)
    .filter(viewable => LINK_INTERFACE_SUPPORTED_TYPES.includes(viewable.__typename))
    .map(viewable => (
      <ClickTracker
        key={viewable.id}
        component="GenresList"
        eventName="click_genre"
        element="genre"
        action="click"
        clickType="navigation"
        viewableId={viewable.id}
      >
        <GenreCard genre={viewable} />
      </ClickTracker>
    ));

  return (
    <PageWrapper background>
      <Metadata
        title={view.title}
        description={view.description}
        imageUrl={view.image}
      />
      <Content>
        {items.length ? (
          <Grid itemWidth={imageTypes.genreCard.width} spaceAround autoFillColumns>
            {items}
          </Grid>
        ) : (
          <ErrorPage>
            <Text id="genre.noContent" />
          </ErrorPage>
        )}
      </Content>
    </PageWrapper>
  );
}
GenresView.propTypes = {
  location: locationShape.isRequired,
};

export default React.memo(GenresView);
