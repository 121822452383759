import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { BLOCK_INTERFACE_TYPES, BROWSER_TYPES } from 'utils/constants';
import {
  useCollectionUIStyles,
  useNotifyOnce,
} from 'utils/hooks';
import { Carousel } from 'components';
import { collectionShape } from './prop-types';
import { getListOfTrailers } from './helper';
import LiveChannels from './LiveChannels/LiveChannels';
import SixteenNineItems from './SixteenNineItems/SixteenNineItems';
import PosterRow from './PosterRow/PosterRow';
import Genres from './Genres/Genres';
import Featured from './Featured/Featured';
import FeaturedPromo from './Featured/Promo';
import Watchlist from './Watchlist/Watchlist';
import getCollectionQuery from './getCollection.gql';

function Collection(props) {
  const {
    pageId,
    collection,
    refreshData,
    watchlistEnabled,
  } = props;

  const {
    __typename: typename,
    magineId,
    title,
    viewables,
    links,
    collectionUI,
    hasNextPage,
    endCursor,
  } = collection;

  const {
    isRTL,
    isLoggedIn,
    hideChannelLogo,
    browser,
  } = useSelector(state => ({
    isRTL: state.settings.l10n.direction === 'rtl',
    isLoggedIn: state.auth.isLoggedIn,
    hideChannelLogo: !!state.settings.features.hideThumbnailChannelLogo,
    browser: state.common.browser,
  }), shallowEqual);

  const isSafari = browser === BROWSER_TYPES.SAFARI;
  const collectionUIStyles = useCollectionUIStyles(collectionUI);

  const notify = useNotifyOnce();

  // load more items to this collection
  const [getCollection, { loading, fetchMore }] = useLazyQuery(
    getCollectionQuery,
    {
      variables: {
        collectionId: magineId,
        blocksFirst: 16,
        cursor: endCursor,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const loadMore = () => {
    if (!loading && hasNextPage) {
      (fetchMore || getCollection)({
        variables: {
          collectionId: magineId,
          blocksFirst: 16,
          cursor: endCursor,
        },
      });
    }
  };

  switch (typename) {
    case BLOCK_INTERFACE_TYPES.FeaturedCollection: {
      const viewable = viewables[0];
      if (!viewable) {
        notify(`Featured collection ${magineId} "${title}" doesn't contain any items.`);
        return null;
      }

      return (
        <Featured
          viewable={viewable}
          collectionId={magineId}
          collectionUI={collectionUI}
          active
          // MDM-23342 - disable lazy loading of channel logo if it is safari
          lazyChannelLogo={!isSafari}
        />
      );
    }

    case BLOCK_INTERFACE_TYPES.FeaturedCarouselCollection: {
      if (!viewables.length) {
        notify(`Featured carousel collection ${magineId} "${title}" doesn't contain any items.`);
        return null;
      }

      const {
        carouselInterval = 0,
        carouselPager = false,
        carouselNavigation = false,
      } = collectionUI || {};

      return (
        <Carousel
          size={viewables.length}
          withPager={carouselPager}
          withNavigation={carouselNavigation}
          interval={carouselInterval}
          isRTL={isRTL}
          autoRotateAvailability={getListOfTrailers(viewables, isLoggedIn)}
        >
          {(pos, active, next) => (
            <Featured
              viewable={viewables[pos]}
              collectionId={magineId}
              collectionUI={collectionUI}
              active={active}
              onTrailerEnd={next}
              carousel
              // MDM-23342 - disable lazy loading of channel logo if first slide and is safari
              lazyChannelLogo={!(isSafari && pos === 0)}
            />
          )}
        </Carousel>
      );
    }

    case BLOCK_INTERFACE_TYPES.FavouriteChannelsCollection: {
      return (
        <LiveChannels
          title={title}
          pageId={pageId}
          collectionId={magineId}
          categoryKind={typename}
          channels={viewables}
          withEdit
          hasNextPage={hasNextPage}
          refreshData={refreshData}
          hideChannelLogo={collectionUI?.hideChannelLogo ?? hideChannelLogo}
          loadMore={loadMore}
        />
      );
    }

    case BLOCK_INTERFACE_TYPES.LiveChannelsCollection: {
      return (
        <LiveChannels
          title={title}
          pageId={pageId}
          collectionId={magineId}
          categoryKind={typename}
          channels={viewables}
          refreshData={refreshData}
          hasNextPage={hasNextPage}
          hideChannelLogo={collectionUI?.hideChannelLogo ?? hideChannelLogo}
          loadMore={loadMore}
        />
      );
    }

    case BLOCK_INTERFACE_TYPES.SixteenNineCollection:
      return (
        <SixteenNineItems
          pageId={pageId}
          collectionId={magineId}
          categoryKind={typename}
          title={title}
          viewables={viewables}
          refreshData={refreshData}
          hasNextPage={hasNextPage}
          collectionUI={collectionUIStyles}
          hideChannelLogo={collectionUI?.hideChannelLogo ?? hideChannelLogo}
          loadMore={loadMore}
        />
      );

    case BLOCK_INTERFACE_TYPES.EntitledContentCollection:
      return (
        <PosterRow
          pageId={pageId}
          collectionId={magineId}
          categoryKind={typename}
          title={title}
          viewables={viewables}
          refreshData={refreshData}
          hasNextPage={hasNextPage}
          thumbnailSize={collectionUIStyles?.collectionThumbnailsSize}
          hideChannelLogo={collectionUI?.hideChannelLogo ?? hideChannelLogo}
          loadMore={loadMore}
        />
      );

    case BLOCK_INTERFACE_TYPES.PosterCollection:
      return (
        <PosterRow
          pageId={pageId}
          collectionId={magineId}
          categoryKind={typename}
          title={title}
          viewables={viewables}
          refreshData={refreshData}
          hasNextPage={hasNextPage}
          thumbnailSizeFactor={collectionUIStyles?.collectionThumbnailsSize}
          hideChannelLogo={collectionUI?.hideChannelLogo ?? hideChannelLogo}
          loadMore={loadMore}
        />
      );

    case BLOCK_INTERFACE_TYPES.LinkCollection:
      return (
        <Genres
          pageId={pageId}
          collectionId={magineId}
          categoryKind={typename}
          title={title}
          links={links}
          hasNextPage={hasNextPage}
          loadMore={loadMore}
        />
      );

    case BLOCK_INTERFACE_TYPES.BookmarksCollection:
      if (!watchlistEnabled) {
        notify(`Watchlist is disabled, ignoring ${BLOCK_INTERFACE_TYPES.BookmarksCollection} category`);
        return null;
      }

      return (
        <Watchlist
          pageId={pageId}
          collectionId={magineId}
          categoryKind={typename}
          title={title}
          viewables={viewables}
          refreshData={refreshData}
          hasNextPage={hasNextPage}
          hideChannelLogo={collectionUI?.hideChannelLogo ?? hideChannelLogo}
          loadMore={loadMore}
        />
      );

    case BLOCK_INTERFACE_TYPES.ContinueWatchingCollection:
      return (
        <SixteenNineItems
          pageId={pageId}
          collectionId={magineId}
          categoryKind={typename}
          title={title}
          viewables={viewables}
          refreshData={refreshData}
          hasNextPage={hasNextPage}
          collectionUI={collectionUIStyles}
          hideChannelLogo={collectionUI?.hideChannelLogo ?? hideChannelLogo}
          loadMore={loadMore}
        />
      );

    case BLOCK_INTERFACE_TYPES.PromoBlock:
      return (
        <FeaturedPromo collection={collection} />
      );

    default:
      notify(`Got unexpected collection type ${typename} in the contentList, ignoring it`);
      return null;
  }
}

Collection.propTypes = {
  pageId: PropTypes.string.isRequired,
  collection: collectionShape.isRequired,
  refreshData: PropTypes.func.isRequired,
  watchlistEnabled: PropTypes.bool.isRequired,
};

export default React.memo(Collection);
