import React, { useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  entitledPackShape,
  discountShape,
} from 'reducers/packs';
import { uncancelPackage, fetchEntitledPacks } from 'actions/packs';
import { addMessage } from 'actions/messages';
import {
  EntitlementStatus,
  ENTITLEMENT_TYPES,
  DATE_FORMAT,
} from 'utils/constants';
import {
  Link,
  Price,
  Text,
  TextDate,
  Box,
  PrimaryButton,
  SecondaryButton,
  LinkButton,
  TVODCountdown,
} from 'components';
import RouterLink from 'router/Link';
import { Usps } from 'components/Payment';
import { useI18n } from 'components/I18n';
import createComponent from 'styles/fela/createComponent';
import PromoCodeModal from 'views/Checkout/PromoCodeModal';
import { extractPromocodeValidationError } from 'actions/campaign';
import Discount from './Discount';
import CancelPackModal from './CancelPackModal';
import { useApplyPromoCodeMutation } from './applyPromoCode.generated';
import { useFela } from 'react-fela';

const StyledText = createComponent(({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontWeight: 'bold',
}), Text);

const tvodCountdownRules = ({ theme }) => ({
  fontWeight: 'bold',
  color: theme.color.secondary,
});

export function isSubscriptionCancelled(pack) {
  return pack?.__typename === ENTITLEMENT_TYPES.SUBSCRIBE
    // MDM-10123 Active and expired subscriptions should be cancellable
    && pack?.status === EntitlementStatus.cancelled;
}

const isPass = pack => pack?.__typename === ENTITLEMENT_TYPES.PASS;

function Pack({ pack, discount }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [inProgressId, setInProgressId] = useState(0);
  const promoCodeRef = useRef('');
  const isPromoCodeAllowed = useSelector(state => state.settings.features.payment?.allowPromoCodes);
  const offerRef = useRef(null);
  const i18n = useI18n();
  const { css } = useFela();

  const [applyPromoCode] = useApplyPromoCodeMutation({
    onCompleted() {
      dispatch(fetchEntitledPacks());
      dispatch(addMessage({
        contentId: 'subscription.promoCodeSuccess',
        type: 'success',
      }));
      setShowPromoModal(false);
    },
    onError(e) {
      throw i18n.formatText(
        extractPromocodeValidationError(JSON.stringify(e)),
        { promoCode: promoCodeRef.current },
      );
    },
  });
  const applyPromoCodeHandler = (promoCode) => {
    promoCodeRef.current = promoCode;
    return applyPromoCode({ variables: { promoCode } });
  };

  const showCancelModal = () => setShowModal(true);
  const hideCancelModal = () => setShowModal(false);

  const handlePromoCodeClick = useCallback((offer) => {
    setShowPromoModal(true);
    offerRef.current = offer;
  }, [offerRef]);

  const handlePromoCodeModalClose = useCallback(() => {
    setShowPromoModal(false);
    offerRef.current = null;
  }, [offerRef]);

  if (pack.__typename === ENTITLEMENT_TYPES.THIRDPARTY) {
    return (
      <>
        <Box my="1rem">
          <StyledText>
            {pack.name}
          </StyledText>
        </Box>

        <Usps usps={pack.usps} />

        {pack.status === EntitlementStatus.cancelled && (
          <>
            (<Text id="canceled" />)&nbsp;
            <Text
              id="subscription.availableUntil"
              values={{
                date: <TextDate key="endDate" value={pack.endDate} options={DATE_FORMAT} />,
              }}
            />
          </>
        )}
      </>
    );
  }

  const nextBillingDate = discount?.endDate || pack.endDate;

  const recoverEntitlement = async (id) => {
    setInProgressId(id);
    try {
      await dispatch(uncancelPackage(id));

      dispatch(addMessage({
        contentId: 'subscription.successRestart',
        type: 'success',
      }));
    } catch (e) {
      dispatch(addMessage({ contentId: 'failedMessage' }));
    }

    setInProgressId(null);
  };

  const expiredWithFailedRenewal = pack.status === EntitlementStatus.expired
    && pack.failedRenewals > 0;

  const showPrice = () => {
    if (discount) {
      return <Discount pack={pack} discount={discount} />;
    }
    if (pack.status !== EntitlementStatus.cancelled && !expiredWithFailedRenewal) {
      /**
       Temporarily hide price when the pack is cancelled, so we don't show
       incorrect price for offers partners.
       Fixme: to be removed when MDM-9165 is fixed from the backend if the
       `netPrice` for cancelled packages is no longer 0.
       */
      return (
        <StyledText
          id="payment.price"
          values={{
            period: pack.recurringPeriod,
            price: (
              <Price key="price" price={pack.netPrice} currency={pack.currency} />
            ),
          }}
        />
      );
    }
    return null;
  };

  const refreshData = () => {
    dispatch(fetchEntitledPacks());
  };

  return (
    <>
      <Box>
        <StyledText>
          {pack.name}
        </StyledText>
      </Box>

      {/* if we have Expired Pack */}
      {expiredWithFailedRenewal && (
        <>
          <Box mt="medium" mb="small">
            <Text
              bold
              id="subscription.expiredTitle"
            />
          </Box>
          <Text
            id="subscription.expiredMessage"
          />
          <Box mt="medium">
            <StyledText
              id="payment.price"
              values={{
                period: pack.recurringPeriod,
                price: (
                  <Price key="price" price={pack.netPrice} currency={pack.currency} />
                ),
              }}
            />
          </Box>

          <Box mt="medium" mb="medium">
            <Link to={{ name: 'settings-payment' }}>
              <Text
                id="subscription.verifyMethod"
                fontSize="medium"
              />
            </Link>
          </Box>
        </>
      )}

      {pack.status === EntitlementStatus.cancelled && (
        <Box mt="fine" mb="medium">
          <Text
            id="subscription.canceledMessage"
            color="secondary"
            bold
            values={{
              date: <TextDate key="endDate" value={pack.endDate} options={DATE_FORMAT} />,
            }}
          />
        </Box>
      )}

      {pack.status !== EntitlementStatus.cancelled
        && nextBillingDate && pack.__typename === ENTITLEMENT_TYPES.SUBSCRIBE && (
        <Box mt="fine" mb="medium">
          <Text
            id="subscription.nextBilling"
            color="secondary"
            bold
            values={{
              date: <TextDate key="nextBillingDate" value={nextBillingDate} options={DATE_FORMAT} />,
            }}
          />
        </Box>
      )}

      {pack.__typename === ENTITLEMENT_TYPES.PASS && pack.endDate &&  (
        <Box mt="fine" mb="medium" className={css(tvodCountdownRules)}>
          <TVODCountdown entitlement={pack} refreshData={refreshData} />
        </Box>
      )}

      <Box row justifyContent="left">
        <Usps
          my="medium"
          usps={pack.usps}
          type="bullet"
          size="small"
        />
      </Box>

      <Box mt="medium" mb="large">
        {showPrice()}
      </Box>

      {(!isSubscriptionCancelled(pack) && (pack.isOfferChangeable || isPromoCodeAllowed)) && (
        <Box row wrap justifyContent="left">
          {pack.isOfferChangeable && (
            <Box mb="small" mr="medium">
              <PrimaryButton
                fixedWidth
                variant="brand"
                to={{
                  name: 'offers-change',
                  params: {
                    id: pack.id,
                  },
                }}
                as={RouterLink}
              >
                <Text id="changeOffer" />
              </PrimaryButton>
            </Box>
          )}
          {isPromoCodeAllowed && (
            <Box mb="small" mr="medium">
              <SecondaryButton
                fixedWidth
                variant="brand"
                onClick={() => handlePromoCodeClick(pack)}
              >
                <Text id="subscription.settingsPromoCode" />
              </SecondaryButton>
            </Box>
          )}
        </Box>
      )}

      {!isPass(pack) && !isSubscriptionCancelled(pack) && (
        <LinkButton
          inline
          variant="brand"
          onClick={showCancelModal}
        >
          <Text id="cancelPackage" />
        </LinkButton>
      )}

      {pack.status === EntitlementStatus.cancelled && (
        <PrimaryButton
          fixedWidth
          variant="brand"
          showSpinner={pack.id === inProgressId}
          onClick={() => recoverEntitlement(pack.id)}
        >
          <Text id="restartSubscription" />
        </PrimaryButton>
      )}

      {showModal && (
        <CancelPackModal
          pack={pack}
          onDismiss={hideCancelModal}
        />
      )}

      {showPromoModal && (
        <PromoCodeModal
          offer={offerRef.current}
          validatePromocode={applyPromoCodeHandler}
          onClose={handlePromoCodeModalClose}
        />
      )}
    </>
  );
}

Pack.propTypes = {
  pack: entitledPackShape.isRequired,
  discount: discountShape,
};

export default Pack;
