import React, { useCallback, useEffect, useState } from 'react';
import Link from 'router/Link';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BLOCK_INTERFACE_TYPES } from 'utils/constants';
import { useIsMediumScreen, useIsMountedRef } from 'utils/hooks';
import { useAnalytics } from 'components/Tracking';
import ObservableInterval from 'utils/observable-interval';
import {
  getLiveEventState,
  extractLiveEventTime,
  ifLiveEventIsItAvailable,
} from 'utils/live-event';
import { Box } from 'components';
import { viewableShape } from 'views/ContentListView/prop-types';
import PlayButton from 'views/WatchView/ViewableInfo/PlayButton';
import InfoButton from 'components/InfoButton/InfoButton';
import OfferButtons from 'components/OfferButtons';


const interval = new ObservableInterval(1000);

export default function ActionButtons({
  viewable, // Show's S1:E1 or Viewable
  collectionId,
  viewableId, // Real viewableId
  carousel,
}) {
  const {
    isLoggedIn,
    isSignUpViaMovistar,
    videoPreview,
  } = useSelector(state => ({
    isLoggedIn: state.auth.isLoggedIn,
    isSignUpViaMovistar: state.user.isSignUpViaMovistar,
    videoPreview: state.settings.features.videoPreview,
  }));

  const analytics = useAnalytics();
  const isMediumScreen = useIsMediumScreen();
  const liveEventTime = extractLiveEventTime(viewable);
  const isMounted = useIsMountedRef();
  const [, setLiveEventState] = useState(null);

  const isPlayAvailable = viewable.entitlement && ifLiveEventIsItAvailable(viewable);
  const dataId = carousel ? 'carousel-link' : 'featured-link';

  const updateLiveEventState = () => {
    if (!isMounted.current) return;
    setLiveEventState(getLiveEventState(liveEventTime));
  };

  useEffect(() => {
    if (!liveEventTime) return;
    updateLiveEventState();

    interval.subscribe(updateLiveEventState);

    // eslint-disable-next-line consistent-return
    return () => {
      interval.unsubscribe(updateLiveEventState);
    };
  }, [liveEventTime]);

  const getExtraProps = useCallback(() => ({
    collectionId,
    categoryKind: carousel ? BLOCK_INTERFACE_TYPES.FeaturedCarouselCollection : BLOCK_INTERFACE_TYPES.FeaturedCollection,
    viewableId,
    isPlayAvailable,
    hasTrailer: !!videoPreview
      && (!!viewable.trailer || (isLoggedIn && !!viewable.trailers?.length)),
  }), []);

  const trackClick = useCallback((area) => {
    analytics.onClick({
      component: carousel ? 'carousel' : 'featured',
      action: 'click',
      clickType: 'asset',
      eventName: 'click_asset',
      viewableId: viewableId,
      extra: {
        ...getExtraProps(),
        area,
      },
    });
  }, []);

  return (
    <Box flexBox wrap rowGap="small">
      {!viewable.entitlement && !isSignUpViaMovistar && (
        <OfferButtons
          dataId={dataId}
          viewable={viewable}
          onClick={() => trackClick('offer-btn')}
        />
      )}

      {isPlayAvailable && (
        <Box width="100%" sm-width="auto" sm-mr="small">
          <PlayButton
            data-id={dataId}
            data-value="play-btn/autoplay"
            to={{ name: 'watch', params: { id: viewableId }, query: { autoplay: true } }}
            as={Link}
            onClick={() => trackClick('play-btn')}
          />
        </Box>
      )}

      <InfoButton
        to={{ name: 'watch', params: { id: viewableId }, query: { info: true } }}
        data-id={dataId}
        data-value="info-btn/info"
        short={!isMediumScreen}
        big
        onClick={() => trackClick('info-btn')}
      />
    </Box>
  );
}

ActionButtons.propTypes = {
  viewableId: PropTypes.string.isRequired,
  collectionId: PropTypes.string.isRequired,
  viewable: viewableShape.isRequired,
  carousel: PropTypes.bool,
};
