import React from 'react';
import PropTypes from 'prop-types';
import { OFFER_TYPES } from 'utils/constants';
import { useOfferButtonClickHandler } from 'utils/hooks';
import { offerShape, viewableShape } from 'types/viewableShape';
import {
  Price,
  Box,
  PrimaryButton,
  SecondaryButton,
  Text,
} from 'components';
import { PlayBtnIcon } from 'components/Icons';

const MSG_IDS = {
  [OFFER_TYPES.BuyType]: 'offer.buy',
  [OFFER_TYPES.RentType]: 'offer.rent',
  [OFFER_TYPES.SubscribeType]: 'offer.subscribe',
  [OFFER_TYPES.PassType]: 'offer.pass',
  [OFFER_TYPES.DefaultType]: 'offer.signup',
};

function getButtonLabel(offer, isSingleOffer) {
  // second button includes all other offer types
  if (!offer) {
    return (<Text id="offer.multi" />);
  }

  if (offer.__typename === OFFER_TYPES.DefaultType) {
    return (
      <Box row alignItems="center" gap="fine">
        <PlayBtnIcon />
        <Text
          lineHeight="1.3em"
          id={MSG_IDS[OFFER_TYPES.DefaultType]}
        />
      </Box>
    );
  }

  const price = (
    <Price key="price" price={offer.priceInCents} currency={offer.currency} />
  );

  return (
    <>
      <Text
        id={MSG_IDS[offer.__typename]}
      /> {isSingleOffer ? price : (
        <Text
          id="fromPrice"
          values={{ price }}
        />
      )}
    </>
  );
}

function OfferButton({
  viewable,
  offer,
  isSingleOffer,
  types,
  isPrimary,
  dataId,
  onClick,
}) {
  const offerButtonClickHandler = useOfferButtonClickHandler(viewable);
  const Component = isPrimary ? PrimaryButton : SecondaryButton;

  const clickHandler = () => {
    offerButtonClickHandler(types);
    onClick?.(types);
  };

  return (
    <Box width="100%" sm-width="auto" sm-mr="small">
      <Component
        variant="brand"
        onClick={clickHandler}
        nowrap
        data-cy={`${types}-button`}
        data-id={dataId}
        data-value={`offer-button/${types}`}
        minWidth="auto"
        fullWidth
        sm-fullWidth={false}
      >
        {getButtonLabel(offer, isSingleOffer)}
      </Component>
    </Box>
  );
}

OfferButton.propTypes = {
  viewable: viewableShape.isRequired,
  offer: offerShape,
  isSingleOffer: PropTypes.bool,
  isPrimary: PropTypes.bool,
  types: PropTypes.string,
  dataId: PropTypes.string,
  onClick: PropTypes.func,
};

export default OfferButton;
