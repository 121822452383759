import createComponent from 'styles/fela/createComponent';
import CleanLink from 'components/StyledSystem/CleanLink';
import { BUTTON_HEIGHT, BUTTON_HEIGHT_BIG } from 'utils/constants';

export const Container = createComponent(({ theme, short }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: short ? 'inline-block' : 'flex',
  fontSize: theme.fontSize.normal,
  letterSpacing: '0.5px',
  userSelect: 'none',
  paddingRight: theme.margin.small,

  '& svg path': {
    fill: theme.color.brand,
  },
  '& svg circle': {
    stroke: theme.color.brand,
    fill: 'transparent',
    transition: 'fill 0.2s ease-in-out',
  },
  ':hover svg circle': {
    fill: theme.color.secondaryButtonHover,
  },
}), CleanLink, ['short']);

export const IconContainer = createComponent(({ big }) => ({
  position: 'relative',
  width: big ? BUTTON_HEIGHT_BIG : BUTTON_HEIGHT,
  height: big ? BUTTON_HEIGHT_BIG : BUTTON_HEIGHT,
}), 'div', ['big']);
